import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Card = ({ item }: any) => {
  return (
    <>
      <div className="collections-card">
        <div className="card-img">
          <img src={item.preview} alt="" />
        </div>
        <hr />
        <div style={{ width: "100%", textAlign: "center" }}>
          <h5>{item.name}</h5>
          <div
            className={
              item.isStake ? "card-item-btns isStake-btns" : "card-item-btns"
            }
          >
            {
              item?.external_link !== "" ? <a href={item?.external_link} target="_blank">
                <button className="mint connect-wallet-btn">Mint</button>
              </a> : <Link to={item.value === "MultiWorms2" ? `/mint_worms` : `/mint/${item.value}`}>
                <button className="mint connect-wallet-btn">Mint</button>
              </Link>
            }
            {item.isStake && (
              <Link to={`/stake/${item.value}`}>
                <button className="mint connect-wallet-btn">Stake</button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
