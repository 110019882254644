import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Modal from "@mui/material/Modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Toaster } from "react-hot-toast";
import "./global.scss";
import { connectors, connectorLocalStorageKey } from "./utils/connectors";
import { useEagerConnect } from "./hooks/useEagerConnect";
import { useInactiveListener } from "./hooks/useInactiveListener";
import { getErrorMessage } from "./utils/ethereum";
import Header from "./components/Header/Header";
import Collections from "components/Collections/Collections";
import MintPage from "components/MintPage/MintPage";
import Footer from "./components/Footer/Footer";
import StakePage from "components/StakePage/StakePage";
import { useAxios } from "hooks/useAxios";
import MultiWorms from "components/MultiWormsPage/MultiWormsPage";

function App() {
  const location = useLocation();
  useAxios();
  const [errorModalOpen, setErrorModalOpen] = useState(null);
  const [networkError, setNetworkError] = useState(null);

  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: 300,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 4, 3),
    },
  }));

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const { account, activate, active, connector, chainId } = useWeb3React();
  const connectToProvider = (connector) => {
    activate(connector);
  };

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // mount only once or face issues :P
  const [triedEager, triedEagerError] = useEagerConnect();
  const { activateError } = useInactiveListener(
    !triedEager || !!activatingConnector
  );

  // handling connection error
  if ((triedEagerError || activateError) && errorModalOpen === null) {
    const errorMsg = getErrorMessage(triedEagerError || activateError);
    setNetworkError(errorMsg);
    setErrorModalOpen(true);
  }

  const closeErrorModal = () => {
    window.localStorage.setItem(connectorLocalStorageKey, connectors[0].key);
    window.location.reload();
  };
  
  return (
    <React.Fragment>
      <Router>
        <Toaster
          position="top-left"
          toastOptions={{
            success: {
              duration: 3000,
              style: {},
            },
            error: {
              duration: 3000,
              style: {},
            },
          }}
        />
        <Header />
        <div className="header-breaker"></div>
        <Switch>
          <Route exact path="/" render={() => <Collections />} />
          <Route
            exact
            path="/mint/:collectionvalue"
            render={() => <MintPage />}
          />
          <Route
            exact
            path="/mint_worms"
            render={() => <MultiWorms />}
          />
          <Route exact path="/stake/:collectionvalue" render={() => <StakePage />} />
        </Switch>
      </Router>
      <Modal
        open={!!errorModalOpen && !active}
        onClose={(event: any, reason: any) => {
          if (reason === "backdropClick") {
            return false;
          }
          if (reason === "escapeKeyDown") {
            return false;
          }
          setErrorModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={`${classes.paper} modal-div`}>
          <p>{networkError}</p>
          <Button
            className=""
            onClick={closeErrorModal}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </div>
      </Modal>
      <div className="footer-breaker"></div>
      <Footer />
    </React.Fragment>
  );
}

export default App;
