import "@ethersproject/shims";
import { BigNumber, ethers } from "ethers";
import { getContractObj, getCollectionContract, getContractInfo } from ".";
import getNodeUrl from "./getRpcUrl";
import { MultiWormsInfo, NFTMintEngineDetail, NFTStakingInfo } from "./typs";
import WhiteListMap from "./FreeMap.json";
import { toast } from "react-hot-toast";

export function isAddress(address) {
  try {
    ethers.utils.getAddress(address);
  } catch (e) {
    return false;
  }
  return true;
}

export function toEth(amount) {
  return ethers.utils.formatEther(String(amount));
}

export function toWei(amount) {
  return ethers.utils.parseEther(String(amount));
}

export async function isTokenApprovedForCollection(account, amount, chainId, provider) {
  const _contract = getContractObj('MultiWorms2', chainId, provider);
  const tokenContract = getContractObj('DIRT', chainId, provider);
  const allowance = await tokenContract.allowance(account, _contract.address);
  if (BigNumber.from(toWei(amount)).gt(allowance)) {
    return false;
  }
  return true;
}

export async function approveTokenForStaking(chainId, signer) {
  const stakingContract = getContractObj('MultiWorms2', chainId, signer);
  const tokenContract = getContractObj('DIRT', chainId, signer);

  const approveAmount = '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';
  try {
    const approve_tx = await tokenContract.approve(stakingContract.address, approveAmount);
    await approve_tx.wait(1);
    return true;
  } catch (e) {
    const revertMsg = JSON.parse(JSON.stringify(e))["reason"];
    if (revertMsg) toast.error(revertMsg.replace("execution reverted: ", ""));
    return false;
  }
}

export async function purchase(collectionValue, chainId, provider, account, numberOfTokens, isPresale) {
  const _contract = getContractObj(collectionValue, chainId, provider);
  try {
    if (!isPresale) {
      const [mintCost] = await Promise.all([_contract.PRICE()]);
      var nftPrice: BigNumber = ethers.utils.parseEther(ethers.utils.formatEther(mintCost));
      const tx = await _contract.purchase(numberOfTokens, {
        value: nftPrice.mul(numberOfTokens)
      });
      await tx.wait(1);
    } else {
      const proof = WhiteListMap.claims[account]?.proof;
      const index = WhiteListMap.claims[account]?.index;
      const amount = WhiteListMap.claims[account]?.amount;
      if (index === undefined || amount === undefined || proof === undefined) {
        toast.error("You are not registered to WhiteList or PreSale");
        return false;
      }
      const tx = await _contract.preSale(numberOfTokens, BigNumber.from(index), BigNumber.from(amount), proof);
      await tx.wait(1);
    }
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function purchaseWorms(chainId, provider, account, numberOfTokens, price, isPresale ) {
  const _contract = getContractObj("MultiWorms2", chainId, provider);
  try {
    if (!isPresale) {
      const tx = await _contract.purchase(numberOfTokens, {
        value: ethers.utils.parseEther(String(price)).mul(numberOfTokens)
      });
      await tx.wait(1);
    } else {
      const proof = WhiteListMap.claims[account]?.proof;
      const index = WhiteListMap.claims[account]?.index;
      const amount = WhiteListMap.claims[account]?.amount;
      if (index === undefined || amount === undefined || proof === undefined) {
        toast.error("You are not registered to WhiteList or PreSale");
        return false;
      }
      const tx = await _contract.preSale(numberOfTokens, BigNumber.from(index), BigNumber.from(amount), proof);
      await tx.wait(1);
    }
    return true;
  } catch (e) {
    console.log(e)
    const revertMsg = JSON.parse(JSON.stringify(e))["reason"];
    if (revertMsg) toast.error(revertMsg.replace("execution reverted: ", ""));
    return false;
  }
}
export async function getEngineInfo(collectionValue) {
  const provider = new ethers.providers.JsonRpcProvider(getNodeUrl())
  const PixelBuddiesContract = getContractObj(collectionValue, process.env.REACT_APP_NETWORK_ID, provider);
  try {
    const [
      maxSupply,
      totalSupply,
      ownerAddress,
      mintCost,
    ] = await Promise.all([
      PixelBuddiesContract.MAX_SUPPLY(),
      PixelBuddiesContract.totalSupply(),
      PixelBuddiesContract.owner(),
      PixelBuddiesContract.PRICE(),
    ]);

    const nftMintDetail: NFTMintEngineDetail = {
      maxSupply: maxSupply.toNumber(),
      totalSupply: totalSupply.toNumber(),
      ownerAddress: ownerAddress.toString(),
      mintCost: parseFloat(ethers.utils.formatEther(mintCost)),
    }

    return nftMintDetail;
  } catch (e) {
    console.log(e);
    return null;
  }
}
export async function getMultiWormsInfo() {
  const provider = new ethers.providers.JsonRpcProvider(getNodeUrl())
  const PixelBuddiesContract = getContractObj("MultiWorms2", process.env.REACT_APP_NETWORK_ID, provider);
  try {
    const [
      maxSupply,
      totalSupply,
      mintMTVPrice,
      mintDirtPrice,
      ownerAddress,
    ] = await Promise.all([
      PixelBuddiesContract.MAX_SUPPLY(),
      PixelBuddiesContract.totalSupply(),
      PixelBuddiesContract.FIRST_PRICE(),
      PixelBuddiesContract.LAST_PRICE(),
      PixelBuddiesContract.owner(),
    ]);
    const nftMintDetail: MultiWormsInfo = {
      MAX_SUPPLY: maxSupply.toNumber(),
      TOTAL_SUPPLY: totalSupply.toNumber(),
      MINT_MTV_PRICE: parseFloat(ethers.utils.formatEther(mintMTVPrice)),
      MINT_DIRT_PRICE: parseFloat(ethers.utils.formatEther(mintDirtPrice)),
      OWNER: ownerAddress.toString(),
    }
    return nftMintDetail;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * NFT Contract Management
 */
export async function isNFTApprovedForStake(
  collection: any,
  account: any,
  chainId: any,
  provider: any
) {
  const stakeContract: any = getContractObj(collection.staking_value, chainId, provider);
  const nftToken: any = getCollectionContract(collection.value, collection.address, chainId, provider);
  return await nftToken.isApprovedForAll(account, stakeContract.address);
}

export async function setNFTApprovalForStake(
  collection: any,
  approved: any,
  chainId: any,
  provider: any
) {
  const stakeContract: any = getContractObj(collection.staking_value, chainId, provider);
  const nftToken: any = getCollectionContract(collection.value, collection.address, chainId, provider);
  try {
    const tx = await nftToken.setApprovalForAll(stakeContract.address, approved);
    await tx.wait(1);
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
}

export async function onNFTStake(collection, account, chainId, provider, tokenIdList, rarityList, amountList) {

  try {
    let isApproved = await isNFTApprovedForStake(collection, account, chainId, provider);
    if (!isApproved) isApproved = await setNFTApprovalForStake(collection, true, chainId, provider);
    const _contract = getContractObj(collection.staking_value, chainId, provider);
    const tx = await _contract.stake(tokenIdList, rarityList, amountList, { gasLimit: 2000000 });
    await tx.wait(1);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function onNFTUnStake(collection, account, chainId, provider, tokenIdList, amountList) {

  try {
    let isApproved = await isNFTApprovedForStake(collection, account, chainId, provider);
    if (!isApproved) isApproved = await setNFTApprovalForStake(collection, true, chainId, provider);
    const _contract = getContractObj(collection.staking_value, chainId, provider);
    const tx = await _contract.unstake(tokenIdList, amountList, { gasLimit: 2000000 });
    await tx.wait(1);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function onHarvest(collection, account, chainId, provider) {

  try {
    let isApproved = await isNFTApprovedForStake(collection, account, chainId, provider);
    if (!isApproved) isApproved = await setNFTApprovalForStake(collection, true, chainId, provider);
    const _contract = getContractObj(collection.staking_value, chainId, provider);
    const tx = await _contract.harvest({ gasLimit: 2000000 });
    await tx.wait(1);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getStakingInfo(collectionValue, account) {
  const provider = new ethers.providers.JsonRpcProvider(getNodeUrl());
  const stakingContract = getContractObj(collectionValue, process.env.REACT_APP_NETWORK_ID, provider);
  try {
    const [holdedTokenIdList, stakedTokenIDList, totalEarnd] = await Promise.all([
      stakingContract.userHoldNFT(account),
      stakingContract.userStakedNFT(account),
      stakingContract.callStatic.getTotalEarned(account)
    ]);
    let stokenIdList = [];
    for (const tokenId of stakedTokenIDList) {
      stokenIdList.push(tokenId.toNumber());
    }
    let htokenIdList = [];
    for (const tokenId of holdedTokenIdList) {
      htokenIdList.push(tokenId.toNumber());
    }
    const nftStakingDetail: NFTStakingInfo = {
      holdedTokenIdList: htokenIdList,
      stakedTokenIdList: stokenIdList,
      totalEarned: parseFloat(ethers.utils.formatEther(totalEarnd)),
    }
    return nftStakingDetail;
  } catch (e) {
    console.log(e);
    return null;
  }
}