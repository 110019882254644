import React from "react";
import "./styles.css";

const StakeCard = ({
  staked,
  holded,
  selectedItems,
  key,
  handleOnChange,
  item,
  onStake,
  onUnStake,
  onClaim,
}: any) => {
  return (
    <>
      <div className="collections-card stakeHold" key={key}>
        <div className="card-img">
          <input
            onChange={(e) => handleOnChange(e)}
            value={item?.tokenId}
            name={item?.rarity}
            type="checkbox"
            checked={selectedItems.includes(item?.tokenId)}
          />
          <img src={item.assetUrl} alt="" />
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <div className="stake-card-details">
            <span>
              Name:&nbsp;&nbsp;
              <span>{item.name}</span>
            </span>
            <span>
              Rarity:&nbsp;&nbsp;
              <span>{item?.rarity}</span>
            </span>
            <span>
              Token Id:&nbsp;&nbsp;
              <span>{item?.tokenId}</span>
            </span>
          </div>
          {staked ? (
            <button className="stake-button connect-wallet-btn" onClick={() => onUnStake([item.tokenId], [item.rarity])}>Unstake</button>
          ) : (
            holded && (
              <button className="stake-button connect-wallet-btn" onClick={() => onStake([item.tokenId], [item.rarity])}>Stake</button>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default StakeCard;
