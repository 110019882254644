import "./styles.css";
import StakedNFT from "components/StakedNFT/StakedNFT";
import HoldedNFT from "components/HoldedNFT/HoldedNFT";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useWeb3React } from "@web3-react/core";
import { COLLECTIONS } from "utils";
import "./styles.css";
import { getStakingInfo, onHarvest, onNFTStake, onNFTUnStake } from "utils/contracts";
import toast from "react-hot-toast";
import { NFTStakingInfo } from "utils/typs";

const StakePage = () => {
  const location = useLocation();
  const collectionValue = location.pathname.split("/")[2];
  const [collection, setCollection] = useState(null);
  useEffect(() => {
    for (const _collection of COLLECTIONS) {
      if (_collection.value === collectionValue) {
        setCollection(_collection);
      }
    }
  }, [collectionValue]);

  const { connector, library, account, active, chainId } = useWeb3React();
  const [loginStatus, setLoginStatus] = useState(false);
  let newVariable = process.env.REACT_APP_NETWORK_ID;
  useEffect(() => {
    const isLoggedin =
      account && active && chainId === parseInt(newVariable, 10);
    setLoginStatus(isLoggedin);
  }, [connector, library, account, active, chainId, newVariable]);

  const [stakedItems, setStakedItems] = useState([]);
  const [holdedItems, setHoldedItems] = useState([]);
  const [selectedStakedItems, setSelectedStakedItems] = useState([]);
  const [selectedHoldedItems, setSelectedHoldedItems] = useState([]);
  const [nftStakingInfo, setNftStakingInfo] = useState<NFTStakingInfo>();
  useEffect(() => {
    if (loginStatus) {
      onFetchData();
    }
  }, [loginStatus]);

  function onFetchData() {
    getStakingInfo(collection.staking_value, account).then((nftStakingInfo: NFTStakingInfo) => {
      setNftStakingInfo(nftStakingInfo);
      let paramData = {
        itemCollection: collection.address.toLowerCase(),
        itemOwner: account.toLowerCase(),
      };
      axios
        .get(`/api/stake_item`, { params: paramData })
        .then((res) => {
          const stakedNFT = [];
          const holdedNFT = [];
          for (const item of res.data.items) {
            if (nftStakingInfo.stakedTokenIdList.includes(item.tokenId)) stakedNFT.push(item);
            else if (nftStakingInfo.holdedTokenIdList.includes(item.tokenId)) holdedNFT.push(item);
          }
          setStakedItems([...stakedNFT]);
          setHoldedItems([...holdedNFT]);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  async function onStake(tokenIdList, rarityList) {
    if (tokenIdList.length < 1) return;
    if (tokenIdList.length > 20){
      toast.error("You can stake up-to 20 NFTs at once");
      return;
    }
    const load_toast_id = toast.loading("Staking...");
    let amountList = [0,0,0,0,0];
    for (let i = 0 ; i < rarityList.length; i++){
      amountList[rarityList[i]]++;
    }
    const isStaked = await onNFTStake(collection, account, chainId, library.getSigner(), tokenIdList, rarityList, amountList);
    if (isStaked) toast.success("The NFT is staked successfully!");
    else toast.error("Failed");
    toast.dismiss(load_toast_id);
    onFetchData();
  }

  async function onUnStake(tokenIdList, rarityList) {
    if (tokenIdList.length < 1) return;
    if (tokenIdList.length > 20){
      toast.error("You can unstake up-to 20 NFTs at once");
      return;
    }
    const load_toast_id = toast.loading("UnStaking...");
    let amountList = [0,0,0,0,0];
    for (let i = 0 ; i < rarityList.length; i++){
      amountList[rarityList[i]]++;
    }
    const isStaked = await onNFTUnStake(collection, account, chainId, library.getSigner(), tokenIdList, amountList);
    if (isStaked) toast.success("The NFT is unstaked successfully!");
    else toast.error("Failed");
    toast.dismiss(load_toast_id);
    onFetchData();
  }

  async function onClaim() {
    const load_toast_id = toast.loading("Claiming...");
    const isStaked = await onHarvest(collection, account, chainId, library.getSigner());
    if (isStaked) toast.success("Reward is sent your wallet address successfully!");
    else toast.error("Failed");
    toast.dismiss(load_toast_id);
    onFetchData();
  }

  return (
    <div className="stake-page-style">
      <h2>My Staked {collection?.name}</h2>
      <div className="stacked-details-container">
        <div className="stacked-details">
          <p>{nftStakingInfo?.totalEarned.toFixed(2)} BUD</p>
          <span>Claimable</span>
        </div>
        <div className="stacked-details">
          <p>{stakedItems?.length}</p>
          <span>Staked</span>
        </div>
        <div className="stacked-details">
          <p>{holdedItems?.length}</p>
          <span>Not Staked</span>
        </div>
      </div>
      <div
        style={{
          padding: "0 30px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button className="stake-hold-claim connect-wallet-btn" onClick={() => onClaim()}>Claim</button>
      </div>
      <br/>
      <div className="staked-page">
        <StakedNFT
          selectedStakedItems={selectedStakedItems}
          setSelectedStakedItems={setSelectedStakedItems}
          items={stakedItems}
          onUnStake={onUnStake} />
        <HoldedNFT
          selectedHoldedItems={selectedHoldedItems}
          setSelectedHoldedItems={setSelectedHoldedItems}
          items={holdedItems}
          onStake={onStake} />
      </div>
    </div>
  );
};

export default StakePage;
