import Card from "components/Card/Card";
import StakeCard from "components/Card/stakeCard";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import "./styles.css";
// import { COLLECTIONS } from "utils";
const HoldedNFT = ({ setSelectedHoldedItems, selectedHoldedItems, items, onStake }) => {

  const [page, setPage] = useState(0);
  const [checkedState, setCheckedState] = useState(
    new Array(items.length).fill(false)
  );

  const itemsPerPage = 12;
  const [rarityList, setRarityList] = useState([]);
  const handleOnChange = (e) => {
    const tokenId = parseFloat(e.target.value);
    const idx = selectedHoldedItems.indexOf(tokenId);
    if (idx > -1) {
      selectedHoldedItems.splice(idx, 1) //isExist
      rarityList.splice(idx, 1);
    }else {
      selectedHoldedItems.push(tokenId);
      rarityList.push(parseFloat(e.target.name))
    }
    setSelectedHoldedItems([...selectedHoldedItems]);
    setRarityList([...rarityList]);
  };
  const selectAll = () => {
    const tokenIds = [];
    const raritys = [];
    const pageItems = items.slice(page * itemsPerPage, (page + 1) * itemsPerPage);
    if (selectedHoldedItems.length !== pageItems.length){
      for (const item of pageItems){
        tokenIds.push(item.tokenId);
        raritys.push(item.rarity);
      }
    }
    setSelectedHoldedItems([...tokenIds]);
    setRarityList([...raritys]);
  };

  const displayUsers = items
    .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
    .map((item, index) => {
      return (
        <StakeCard
          staked={false}
          holded={true}
          stakeHold={true}
          checkedState={checkedState}
          handleOnChange={handleOnChange}
          listPage={true}
          key={index}
          item={item}
          onStake={onStake}
          selectedItems={selectedHoldedItems}
        />
      );
    });
  const onPageChange = ({ selected }) => {
    setPage(selected);
  }
  return (
    <div className="staked-nft">
      <div className="list-page">
        <div className="list-header">
          <h4>Not Staked</h4>
          <div>
            <button
              onClick={() => selectAll()}
              className="select-all connect-wallet-btn"
            >
              {selectedHoldedItems.length !== 0 && selectedHoldedItems.length === items.length ? "Clear" : "Select All"}
            </button>
            <button className="select-all connect-wallet-btn" onClick={() => onStake(selectedHoldedItems, rarityList)}>Stake</button>
          </div>
        </div>
        <div className="list-page__container">{displayUsers}</div>
        <br />
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={Math.ceil(items?.length / itemsPerPage)}
        onPageChange={onPageChange}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </div>
  );
};

export default HoldedNFT;
