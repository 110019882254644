import { Contract } from "@ethersproject/contracts";
import MultiBudsNFTAbi from "../contracts/MultiBuds.json";
import MultiBudsStakingAbi from "../contracts/MultiBudsStaking.json";
import AnunnakiBabiesV2Abi from "../contracts/AnunnakiBabiesV2.json";
import CrazyMultiFriendsAbi from "../contracts/CrazyMultiFriends.json";
import ProxBotsTellurianAbi from "../contracts/ProxBotsTellurian.json";
import ApocalypticPandasAbi from "../contracts/ApocalypticPandas.json";
import MultiWorms2NFTAbi from "../contracts/MultiWorms2.json";
import DIRTAbi from "../contracts/DIRT.json";

export const currentNetwork = process.env.REACT_APP_NETWORK_ID;

export const COLLECTIONS = [
  {
    value: "MultiBuds",
    staking_value: "MultiBudsStaking",
    name: "MultiBuds",
    preview: "/collections/MultiBudsNFT.png",
    tokenId: 0, // Starting Token Id
    external_link: "",
    address: "0x29aecD4B05B890624647af82B0FA8C47001D5167",
    isStake: true,
  },
  {
    value: "ProxBotsTellurian",
    staking_value: "ProxBotsTellurianStaking",
    name: "Prox Bots Tellurian Faction",
    preview: "/collections/ProxBotsTellurian.png",
    tokenId: 0, // Starting Token Id
    external_link: '',
    address: "0xA3cDc82351e9C59629feA6806A7433D2d9C999dD",
    isStake: false,
  },
  {
    value: "ProxBotsMachina",
    staking_value: "ProxBotsMachinaStaking",
    name: "Prox Bots Machina",
    preview: "/collections/ProxBotsTellurian.png",
    tokenId: 0, // Starting Token Id
    external_link: '',
    address: "0x74790be6EB1195F27C50535BeAbC6DC0431bcE47",
    isStake: false,
  },
  {
    value: "ProxBotsCelestial",
    staking_value: "ProxBotsCelestialStaking",
    name: "Prox Bots Celestial",
    preview: "/collections/ProxBotsTellurian.png",
    tokenId: 0, // Starting Token Id
    external_link: '',
    address: "0x4236a1b37248d6E44500E3e7FB00BdEE9278F12F",
    isStake: false,
  },
  {
    value: "ProxBotsSatoshi",
    staking_value: "ProxBotsSatoshiStaking",
    name: "Prox Bots Satoshi",
    preview: "/collections/ProxBotsTellurian.png",
    tokenId: 0, // Starting Token Id
    external_link: '',
    address: "0x02b56633Fc76481a0F4be3740DdeaB24E86BF6a0",
    isStake: false,
  },
  {
    value: "CrazyMultiFriends",
    staking_value: "CrazyMultiFriendsStaking",
    name: "Crazy MultiFriends",
    preview: "/collections/CrazyMultiFriends.gif",
    tokenId: 1, // Starting Token I
    external_link: '',
    address: "",
    isStake: false,
  },
  // {
  //   value: "AnunnakiBabiesV2",
  //   staking_value: "AnunnakiBabiesV2Staking",
  //   name: "Anunnaki Babies V2",
  //   preview: "/collections/AnunnakiBabiesV2.png",
  //   tokenId: 1, // Starting Token I,
  //   external_link: "",
  //   address: "",
  //   isStake: false
  // },
  {
    value: "ApocalypticPandas",
    staking_value: "ApocalypticPandasStaking",
    name: "ApocalypticPandas",
    preview: "/collections/ApocalypticPandas.png",
    tokenId: 1, // Starting Token I
    external_link: "",
    address: "",
    isStake: false,
  },
  {
    value: "SpaceApes",
    staking_value: "SpaceApesStaking",
    name: "Space Apes",
    preview: "/collections/SpaceApes.jpeg",
    tokenId: 1, // Starting Token I,
    external_link: "https://www.spaceapes.ac",
    address: "",
    isStake: false
  },
  // {
  //   value: "MultiWorms2",
  //   staking_value: "MultiWorms2Staking",
  //   name: "MultiWorms Gen 2",
  //   preview: "/collections/MultiWorms2NFT.gif",
  //   tokenId: 0, // Starting Token Id
  //   external_link: "",
  //   address: "0xd162D3fB9F9F6F59592C62676E1771b96dF5A01e",
  //   isStake: false,
  // },
  // {
  // value: "BrandsSkateboards",
  //  staking_value: "BrandsSkateboardsStaking",
  //  name: "Brands Skateboards",
  //  preview: "/collections/BrandsSkateboards.png",
  //  tokenId: 1, // Starting Token I
  //  external_link: "https://brands.epicmtv.com/",
  //  address: "",
  //  isStake: false,
  // },
];
export const CONTRACTS_BY_NETWORK = {
  [currentNetwork]: {
    MultiBuds: {
      address: "0x29aecD4B05B890624647af82B0FA8C47001D5167",
      abi: MultiBudsNFTAbi,
    },
    MultiBudsStaking: {
      //address: "0x8DAE0468bcCD8C5c9F2E3Ce1aB4fBa420eF21F8a",
      //address: "0xcF89b76e1459F4b0A1fA2Ce778bfF48a031516F4",
      //address: "0x36Fe85B9975C16b16F1bE46A04EAB4c7826C88eF",
      address: "0x2b0A480b4f55B0C31E32Bc6960ceAd407fe6D70E",
      abi: MultiBudsStakingAbi,
    },
    // AnunnakiBabiesV2: {
    //   address: "0xa043CC66A0DBeF6D05Ce6D1ac26ED8ed56e04009",
    //   abi: AnunnakiBabiesV2Abi,
    // },
    CrazyMultiFriends: {
      address: "0x144FfB06Cbd9E9A81Af99dA188876504B7bd5e82",
      abi: CrazyMultiFriendsAbi,
    },
    ProxBotsTellurian: {
      address: "0xA3cDc82351e9C59629feA6806A7433D2d9C999dD",
      abi: ProxBotsTellurianAbi,
    },
    ProxBotsMachina: {
      address: "0x74790be6EB1195F27C50535BeAbC6DC0431bcE47",
      abi: ProxBotsTellurianAbi,
    },
    ProxBotsCelestial: {
      address: "0x4236a1b37248d6E44500E3e7FB00BdEE9278F12F",
      abi: ProxBotsTellurianAbi,
    },
    ProxBotsSatoshi: {
      address: "0x02b56633Fc76481a0F4be3740DdeaB24E86BF6a0",
      abi: ProxBotsTellurianAbi,
    },
    ApocalypticPandas: {
      address: "0xad4aec2bc33e95b509d71ac4d911c0cef218f570",
      abi: ApocalypticPandasAbi,
    },
    // MultiWorms2: {
    //   address: "0xd162D3fB9F9F6F59592C62676E1771b96dF5A01e",
    //   abi: MultiWorms2NFTAbi,
    // },
    // DIRT: {
    //   address: '0x2Eb19Db032dC60039d35E36918d33197D9F7D7b9',
    //   abi: DIRTAbi
    // }
  },
};

export const baseApiUrl = process.env.REACT_APP_API_URL;

export function getContractInfo(name, chainId) {
  if (!chainId) chainId = currentNetwork;

  const contracts = CONTRACTS_BY_NETWORK?.[chainId];
  if (contracts) {
    return contracts?.[name];
  } else {
    return null;
  }
}

export function getContractObj(name, chainId, provider) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(info.address, info.abi, provider);
}

export function getCollectionContract(name, address, chainId, provider) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(address, info.abi, provider);
}

export function truncateWalletString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(0, 7);
  const endStr = walletAddress.substring(lengthStr - 7, lengthStr);
  return startStr + "..." + endStr;
}

export function truncateString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(2, 10);
  const endStr = walletAddress.substring(lengthStr - 5, lengthStr);
  return startStr.toUpperCase() + "..." + endStr.toUpperCase();
}

export function truncateActivityString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(2, 6);
  const endStr = walletAddress.substring(lengthStr - 5, lengthStr);
  return startStr.toUpperCase() + "..." + endStr.toUpperCase();
}

export function truncateHashString(txhash) {
  if (!txhash) return txhash;
  const lengthStr = txhash.length;
  const startStr = txhash.substring(0, 10);
  const endStr = txhash.substring(lengthStr - 10, lengthStr);
  return startStr + "..." + endStr;
}

export const shorter = (str) =>
  str?.length > 8 ? str.slice(0, 6) + "..." + str.slice(-4) : str;