/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import { useLocation } from "react-router";
import { COLLECTIONS } from "utils";
import { getEngineInfo, purchase } from "utils/contracts";
import { NFTMintEngineDetail } from "utils/typs";

import "./Default.css";

function Home() {

  document.body.style.backgroundImage = null;
  
  const location = useLocation();
  const collectionValue = location.pathname.split("/")[2];
  const [collection, setCollection] = useState(null);
  useEffect(() => {
    for (const _collection of COLLECTIONS) {
      if (_collection.value === collectionValue) {
        console.log(_collection);
        setCollection(_collection);
      }
    }
  }, [collectionValue]);

  const [loginStatus, setLoginStatus] = useState(false);
  const { connector, library, chainId, account, active } = useWeb3React();
  const [mintEngineDetail, setMintEngineDetail] =
    useState<NFTMintEngineDetail>(null);

  useEffect(() => {
    const isLoggedin =
      account &&
      active &&
      chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
    setLoginStatus(isLoggedin);
    // if (isLoggedin) {
    //     getNFTNameUpdateRequests();
    // }
  }, [connector, library, account, active, chainId]);
  useEffect(() => {
    getEngineInfo(collectionValue).then(
      (nftMintEngineDetail: NFTMintEngineDetail) => {
        setMintEngineDetail(nftMintEngineDetail);
      }
    );
  }, [loginStatus]);

  const [quantity, setQuantity] = useState(1);

  const sum = (method) => {
    switch (method) {
      case "increase": {
        if (quantity >= 1 && quantity < 10) {
          setQuantity((quantity) => quantity + 1);
        }
        break;
      }
      case "decrease": {
        if (quantity > 1) {
          setQuantity((quantity) => quantity - 1);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <span>
        {days}d: {hours}h: {minutes}m: {seconds}s
      </span>
    );
  };

  const toastNotification = (notificationType) => {
    if (notificationType === "success") {
      // Toaster.success('minting is success', {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    } else if (notificationType === "error") {
      // Toaster.error('minting is failed', {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    }
  };

  const onMint = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }

    if (quantity <= 0) {
      toast.error("Mint Count should be over than 0");
      return;
    }
    const load_toast_id = toast.loading("Please wait for Mint...");
    try {
      const bSuccess = await purchase(
        collectionValue,
        chainId,
        library.getSigner(),
        account,
        quantity,
        false
      );
      if (bSuccess) {
        getEngineInfo(collectionValue).then(
          (nftMintEngineDetail: NFTMintEngineDetail) => {
            setMintEngineDetail(nftMintEngineDetail);
          }
        );
        toast.success("Mint Success!");
      } else {
        toast.error("Mint Failed!");
      }
    } catch (error) {
      toast.error("Mint Failed!");
    }
    toast.dismiss(load_toast_id);
  };

  return (
    <div className="mint-section mt-5 container">
      <h1>{collection?.name}</h1>

      <div className="neoh_fn_mintbox">
        <div className="mint_left">
          <div className="mint_title">
            <span>
              {mintEngineDetail?.totalSupply < 1001 &&
              collectionValue === "PixelBuddiesNFT"
                ? "Presale"
                : "Public"}{" "}
              Mint is Live
            </span>
          </div>
          <div className="mint_list">
            <ul>
              <li>
                <div className="item">
                  <h4>Price</h4>
                  <h3>{mintEngineDetail?.mintCost}MTV</h3>
                </div>
              </li>
              <li>
                <div className="item">
                  <h4>Remaining</h4>
                  <h3>
                    {mintEngineDetail?.maxSupply -
                      mintEngineDetail?.totalSupply}
                    /{mintEngineDetail?.maxSupply}
                  </h3>
                </div>
              </li>
              <li>
                <div className="item">
                  <h4>Quantity</h4>
                  <div className="qnt">
                    <span className="decrease" onClick={() => sum("decrease")}>
                      -
                    </span>
                    <span className="summ">{quantity}</span>
                    <span className="increase" onClick={() => sum("increase")}>
                      +
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="item">
                  <h4>Total Price</h4>
                  <h3>{mintEngineDetail?.mintCost * quantity}MTV + GAS</h3>
                </div>
              </li>
            </ul>
          </div>
          <div className="mint_desc">
            <button onClick={() => onMint()} className="neoh_fn_button">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="512.000000pt"
                  height="512.000000pt"
                  viewBox="0 0 512.000000 512.000000"
                  preserveAspectRatio="xMidYMid meet"
                  className="fn__svg replaced-svg"
                >
                  <g
                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    stroke="none"
                  >
                    <path d="M2148 5097 c-72 -31 -117 -66 -222 -178 -63 -67 -91 -105 -106 -145 -38 -102 -20 -206 48 -286 69 -80 69 -79 34 -72 -85 17 -67 33 -639 -539 -298 -297 -540 -546 -549 -566 -11 -22 -15 -49 -11 -78 5 -49 9 -50 -69 17 -86 74 -207 89 -309 40 -74 -35 -254 -218 -291 -295 -36 -74 -40 -175 -10 -240 28 -60 1274 -1308 1341 -1343 73 -38 168 -37 250 2 75 36 252 211 291 289 53 104 39 223 -36 311 -67 78 -66 76 -26 69 19 -3 48 -2 63 2 18 4 100 78 231 208 l202 202 379 -379 380 -379 -35 -42 c-95 -117 -116 -286 -49 -414 17 -32 202 -225 602 -628 550 -554 581 -585 648 -615 63 -29 80 -33 160 -33 81 0 97 3 160 33 62 29 94 57 267 231 172 172 201 206 230 267 30 62 33 78 33 159 0 77 -5 99 -30 155 -26 60 -68 104 -569 609 -298 299 -567 565 -598 591 -143 121 -334 125 -484 11 l-51 -39 -379 379 -379 379 202 202 c130 131 204 213 208 231 4 15 5 44 2 63 l-6 35 32 -26 c74 -60 94 -73 140 -90 62 -23 146 -16 214 19 78 39 253 216 289 291 39 82 40 177 2 250 -35 67 -1283 1313 -1342 1340 -65 29 -151 30 -218 2z"></path>
                  </g>
                </svg>
              </span>
              <span className="text">Mint Now</span>
            </button>
            {/* <p>
              You can mint up-to 10 items at a time.
            </p> */}
            <p>
              After clicking the “MINT NOW” button, view your NFT on{" "}
              <a href="https://epic.gallery">https://epic.gallery</a>
            </p>
          </div>
        </div>
        <div className="mint_right">
          <div className="mright">
            <img
              className="featured-img"
              src={collection?.preview}
              alt="LazyMint"
            />
            {/* <div className='mint_time'>
              <h4>Public Mint Ends In</h4>
              <h3 className='neoh_fn_countdown'>
                <Countdown date={Date.now() + 123456789} renderer={renderer} /> 
              </h3>
            </div>
            <div className='mint_checked'>
              <p>
                <span className='text'>Presale:</span>
                <span className='status'>
                  Selling{' '}
                  <span className='icon'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      id='Capa_1'
                      enable-background='new 0 0 515.556 515.556'
                      height='512'
                      viewBox='0 0 515.556 515.556'
                      width='512'
                      className='fn__svg replaced-svg'
                    >
                      <path d='m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z'></path>
                    </svg>
                  </span>
                </span>
              </p>
            </div>
            <div className='mint_info'>
              <p>
                You need to pay a GAS fee during minting. We allow max 5 mints
                per wallet.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
