import Card from "components/Card/Card";
import { COLLECTIONS } from "utils";
import "./collections.css";

const Collections = () => {
  document.body.style.backgroundImage = null;
  return (
    <div className="collections">
      {COLLECTIONS.map((item, index) => (
        <Card
          key={index}
          item={item}
        />
      ))}
    </div>
  );
};

export default Collections;
