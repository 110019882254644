import useAuth from "hooks/useAuth";
import "./connectModal.scss";
import Bounce from "react-reveal/Bounce";
import { useEffect, useState } from "react";
interface Props {
  showConnectModal: boolean;
  setShowConnectModal?: any;
}
const ConnectModal: React.FC<Props> = ({
  showConnectModal,
  setShowConnectModal,
}) => {
  const { login } = useAuth();

  const connectMetamask = () => {
    login(1);
    setShowConnectModal(false);
  };
  const connectWalletConnector = () => {
    login(2);
    setShowConnectModal(false);
  };

  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showConnectModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showConnectModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowConnectModal(false);
    }, 800);
  };
  const cloaseHandle = (e: any) => {
    if (e.target.className.includes("connectModal")) {
      onClose();
    }
  };
  return (
    <div
      className={
        showConnectModal === true ? "connectModal active" : "connectModal"
      }
      onMouseDown={(e) => {
        cloaseHandle(e);
      }}
    >
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle m-0">Connect Wallet</h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-angle-double-right"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <div className="metaMask" onClick={connectMetamask}>
              <div className="left">
                <div className="icon">
                  <img src="/assets/metamask.png" alt="" />
                </div>
              </div>
              <div className="middle">
                <h2 className="m-0">Metamask</h2>
                <p className="m-0">Connect using browser wallet</p>
              </div>
              <div className="right">
                <button>
                  <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>

            <div className="wallet" onClick={connectWalletConnector}>
              <div className="left">
                <div className="icon">
                  <img src="/assets/wallet-connect.png" alt="" />
                </div>
              </div>
              <div className="middle">
                <h2 className="m-0">Wallet Connect</h2>
                <p className="m-0">Connect using mobile wallet</p>
              </div>
              <div className="right">
                <button>
                  <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default ConnectModal;
