import Card from "components/Card/Card";
import StakeCard from "components/Card/stakeCard";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./styles.css";

const StakedNFT = ({ setSelectedStakedItems, selectedStakedItems, items, onUnStake }) => {

  const [page, setPage] = useState(0);
  const [checkedState, setCheckedState] = useState(
    new Array(items.length).fill(false)
  );

  const itemsPerPage = 12;
  const [rarityList, setRarityList] = useState([]);
  const handleOnChange = (e) => {
    const tokenId = parseFloat(e.target.value);
    const idx = selectedStakedItems.indexOf(tokenId);
    if (idx > -1) {//isExist
      selectedStakedItems.splice(idx, 1)
      rarityList.splice(idx, 1);
    } else {
      selectedStakedItems.push(tokenId);
      rarityList.push(parseFloat(e.target.name))
    }
    setSelectedStakedItems([...selectedStakedItems]);
    setRarityList([...rarityList]);
  };
  const selectAll = () => {
    const tokenIds = [];
    const raritys = [];
    const pageItems = items.slice(page * itemsPerPage, (page + 1) * itemsPerPage);
    if (selectedStakedItems.length !== pageItems.length) {
      for (const item of pageItems) {
        tokenIds.push(item.tokenId);
        raritys.push(item.rarity);
      }
    }
    setSelectedStakedItems([...tokenIds]);
    setRarityList([...raritys]);
  };

  const displayUsers = items
    .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
    .map((item, index) => {
      return (
        <StakeCard
          staked={true}
          holded={false}
          stakeHold={true}
          checkedState={checkedState}
          handleOnChange={handleOnChange}
          listPage={true}
          key={index}
          item={item}
          onUnStake={onUnStake}
          selectedItems={selectedStakedItems}
        />
      );
    });
  const onPageChange = ({ selected }) => {
    setPage(selected);
  }
  return (
    <div className="staked-nft">
      <div className="list-page">
        <div className="list-header">
          <h4>Staked</h4>
          <div>
            <button onClick={() => selectAll()} className="select-all connect-wallet-btn">
              {selectedStakedItems.length !== 0 && selectedStakedItems.length === items.length ? "Clear" : "Select All"}
            </button>
            <button className="select-all connect-wallet-btn" onClick={() => onUnStake(selectedStakedItems, rarityList)}>Unstake</button>
          </div>
        </div>
        <div className="list-page__container">{displayUsers}</div>
        <br />
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={Math.ceil(items.length / itemsPerPage)}
        onPageChange={onPageChange}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </div>
  );
};

export default StakedNFT;
