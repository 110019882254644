import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./footer.css";

class Footer extends Component {
  render() {
    return (
      <footer className="bg-dark footer-area mt-5">
        {/* Footer Bottom */}
        <div className="container">
          <div className="row ">
            <div className=" col-lg-4 col-12 py-3 text-center">
              <div>
                <Link className="text-white" to="#">
                  Privacy Policy,{" "}
                </Link>{" "}
                <Link className="text-white" to="#">
                  Terms and Services
                </Link>{" "}
              </div>
            </div>
            <div className=" col-lg-4 col-12 py-3">
              <div className="social-icons justify-content-center d-flex  ">
                <a
                  className="discord"
                  target="_blank"
                  href="https://discord.gg/MxRneYPAUx"
                >
                  <i className="fab fa-discord fa-2x px-2" />
                </a>
                <a
                  className="twitter"
                  target="_blank"
                  href="https://twitter.com/EpicGalleryMTV"
                >
                  <i className="fab fa-twitter fa-2x px-2" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-12 py-3 text-center">
              <div className="text-white">
                ©2023 Epic MTV, All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
